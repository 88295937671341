
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10%); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-60px); }
}

  /* .image2.active{
    transform: translateY(-10%);
    opacity: 1;
    animation: fade-right 2s ease-in;

  } */

