.r1{
    transform: rotate(-90deg);


    /* Legacy vendor prefixes that you probably don't need... */
  
    /* Safari */
    -webkit-transform: rotate(-90deg);
  
    /* Firefox */
    -moz-transform: rotate(-90deg);
  
    /* IE */
    -ms-transform: rotate(-90deg);
  
    /* Opera */
    -o-transform: rotate(-90deg);
  
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}