@media screen and (max-width: 768px) {
  .incub {
    flex-direction: column;
  }
  img {
    /* display: none; */
  }
}
@media screen and (max-width: 1080px) {
  .incub {
    flex-direction: row;
    min-width: cover;
  }
  
}
