@media screen and (max-width: 600px)  {
    .abc{
         min-width: 88% ; 
         margin-left: 4%;
    }
  }
  @media screen and (max-width: 800px)  {
    .abc{
         min-width: 95% ; 
    }
  }
  @media screen and (max-width: 600px)  {
    .xyz{
         margin-left: -75%; 
         
    }
  }